<template>
  <div class="appDownload">
    <div class="download_app">
      <div class="download_box">
        <div class="download_name">
          <p>大富健康管家APP</p>
          <!-- <a href="http://bp.sktcm.cn/apk/DiagnosisSys.apk"></a> -->
        </div>
        <div class="download_qrcode">
          <img src="~assets/img/download/PulseBP_Download.png" alt="" />
        </div>
      </div>
      <div class="download_box">
        <div class="download_qrcode">
          <img src="~assets/img/download/DiagnosisSys_Download.png" alt="" />
        </div>
        <div class="download_name">
          <p>臂式电子血压计APP</p>
          <!-- <a href="http://bp.sktcm.cn/apk/PulseBP.apk"></a> -->
        </div>
      </div>
      <div class="download_box">
        <div class="download_name">
          <p>红外热成像APP</p>
          <!-- <a href="http://bp.sktcm.com/apk/tatfook_infrared.apk"></a> -->
        </div>
        <div class="download_qrcode">
          <img src="~assets/img/download/InfraredAPP_Download.png" alt="" />
        </div>
      </div>
      <!-- <div class="download_box">
        <div class="download_name">
          <p>艾灸机器人APP</p>
          <a href="http://bp.sktcm.cn/apk/MoxibustionRobot.apk"></a>
        </div>
        <div class="download_qrcode">
          <img
            src="~assets/img/download/MoxibustionRobot_Download.png"
            alt=""
          />
        </div>
      </div> -->
      <div class="download_tip">
        <div>
          <img src="~assets/img/download/mobile.png" alt="" />
          <p>请在安卓移动端使用</p>
        </div>
        <div>
          <img src="~assets/img/download/android.png" alt="" />
          <p>支持安卓5.0及以上版本</p>
        </div>
      </div>
    </div>
    <div class="download_app">
      <div class="download_box">
        <div class="download_name">
          <p>红外热成像客户端</p>
        </div>
        <div class="download_button">
          <div>
            <a href="http://bp.sktcm.cn/apk/Infrared_Installer.exe"
              >下载到电脑</a
            >
          </div>
        </div>
        <!-- <div class="download_qrcode">
          <img
            src="~assets/img/download/InfraredInstaller_Download.png"
            alt=""
          />
        </div> -->
      </div>
      <div class="download_tip">
        <div>
          <img src="~assets/img/download/pc.png" alt="" />
          <p>请在电脑端使用</p>
        </div>
        <div>
          <img src="~assets/img/download/windows.png" alt="" />
          <p>支持 Windows7 / Windows10</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDownload'
}
</script>

<style scoped>
.appDownload {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}

.download_app {
  margin: 60px auto 100px;
}

.download_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  height: 260px;
  margin: 0 auto;
}
.download_box:nth-of-type(1) {
  background-color: #c7daf1;
  border-radius: 15px 15px 0 0;
}
.download_box:nth-of-type(2) {
  background-color: #b2cceb;
}
.download_box:nth-of-type(3) {
  background-color: #c7daf1;
}

.download_name {
  width: 40%;
}
.download_name p {
  width: 75%;
  height: 80px;
  line-height: 80px;
  margin: 0 auto;
  background-color: #ffffff66;
  border: 2px solid #fff;
  border-radius: 10px;
  color: #333;
  font-size: 28px;
  text-align: center;
}

.download_qrcode {
  width: 40%;
}
.download_qrcode img {
  display: block;
  width: 180px;
  height: 180px;
  border: 1px dashed #396db1;
  border-radius: 5px;
  padding: 10px;
  margin: 0 auto;
}

.download_button {
  width: 40%;
}
.download_button div {
  width: min-content;
  padding: 10px;
  border: 1px dashed #396db1;
  border-radius: 5px;
  margin: 0 auto;
}
.download_button div a {
  display: block;
  width: 240px;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  transition: all 0.3s;
}
.download_button div a:hover {
  background-color: #396db1;
  color: #fff;
  border-radius: 8px;
}

.download_tip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  height: 60px;
  background-color: #75a3e0;
  border-radius: 0 0 15px 15px;
  margin: 0 auto;
}
.download_tip div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 40%;
  color: #ffffff;
}
.download_tip div img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
</style>
